import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import { Button, Col, Row } from 'reactstrap';
import DeleteIcon from 'assets/img/icons/icon_delete.png';
import { getFullUrl } from 'helpers/imageHelpers';
import DiscountInfo from './DiscountInfo';
import { formatAmount } from 'helpers/numberHelper';
import { useDispatch } from 'react-redux';
import { calculatePrice } from 'redux/modules/product/Product';

const OrderAddItem = ({ item, index, isEditable, isShowAction, onDeleteClick, onChangeQty, uomOptions, currency }) => {

  const dispatch = useDispatch();

  const [qty, setQty] = useState(item.qty);
  const [amount, setAmount] = useState(item.totalAmount);

  let uomCode = item.uomCode;
  if (!uomCode && item.uomId && uomOptions) {
    uomCode = uomOptions.find(o => o.value == item.uomId)?.label;
  }

  useEffect(() => {
    setQty(item.qty);
    setAmount(item.totalAmount);
  });

  const handleChangeQty = (index, value) => {
    let quantity = 0;
    if (value) {
      quantity = parseInt(value);
    }

    setQty(value);

    if (item.discountInfos && item.discountInfos.length > 0 && quantity) {
      dispatch(calculatePrice(item.productId, { quantity: quantity, variantId: item.variantId })).then(resp => {
        setAmount(quantity * resp.response.data.data);
        onChangeQty && onChangeQty(index, value, resp.response.data.data);
      });
    } else {
      setAmount(quantity * item.unitPrice);
      onChangeQty && onChangeQty(index, value, item.unitPrice);
    }
  };
  const name = (item.variantId !== null) ? item.variantName : item.productName;
  const validity = (item.variantId !== null) ? item.days : item.productCode;
  const data = (item.variantId !== null) ? item.data : item.variantName;

  return (
    <Row className="d-flex" style={{ margin: `32px 0px` }}>
      {/* {item.source !== 'BillionConnect' && item.variantCode !== 'BillionConnect' && item.source !== 'Tuge' && (
        <Col xs="auto">
          <img className="react-autosuggest__image m-0" src={getFullUrl(item.image)} />
        </Col>
      )} */}
      <Col>
        <div className="muli-bold mb-1" style={{ fontSize: 19 }}>
          {name}
        </div>

        <div style={{ fontSize: 17 }}>
          <b>Validity:</b> {validity} days
          <br />
          <b>Data:</b> {data} GB
        </div>

        <DiscountInfo discounts={item.discountInfos} uomCode={uomCode} currency={currency} />
      </Col>

      <Col xs="auto">
        <Row>
          <div className={cx('order-add-item', { price: isEditable })}>
            <span>{currency}</span>
            <span className="poppins" style={{ fontWeight: 'bold', fontSize: 16 }}>
              {formatAmount(amount)}
            </span>
          </div>

          {!isEditable && <div style={{ height: 20, width: 1, margin: 'auto 24px', backgroundColor: '#E0E0E0' }} />}

          <div className={cx('order-add-item', { qty: isEditable })}>
            <input
              type="number"
              // defaultValue={qty}
              value={qty}
              min={1}
              disabled={!isEditable}
              style={{ width: '100%', border: 0, opacity: 0.5 }}
              onChange={e => handleChangeQty(index, e.target.value)}
            />
            {<span>{uomCode}</span>}
          </div>

          {isShowAction && (
            <>
              <Button className="icon-button" onClick={() => onDeleteClick(index)}>
                <img src={DeleteIcon} alt="Delete" />
              </Button>
            </>
          )}
        </Row>
      </Col>
    </Row>
  );
};

OrderAddItem.defaultProps = {
  item: {},
  isEditable: false,
  isShowAction: true,
};

export default OrderAddItem;
