import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

export const FETCH_CURRENCY_OPTION_REQUEST = 'FETCH_CURRENCY_OPTION_REQUEST';
export const FETCH_CURRENCY_OPTION_SUCCESS = 'FETCH_CURRENCY_OPTION_SUCCESS';
export const FETCH_CURRENCY_OPTION_ERROR = 'FETCH_CURRENCY_OPTION_ERROR';

export const FETCH_CURRENCY_REQUEST = 'FETCH_CURRENCY_REQUEST';
export const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS';
export const FETCH_CURRENCY_ERROR = 'FETCH_CURRENCY_ERROR';

export const FETCH_PREDEFINED_ITEMS_REQUEST = 'FETCH_PREDEFINED_ITEMS_REQUEST';
export const FETCH_PREDEFINED_ITEMS_SUCCESS = 'FETCH_PREDEFINED_ITEMS_SUCCESS';
export const FETCH_PREDEFINED_ITEMS_ERROR = 'FETCH_PREDEFINED_ITEMS_ERROR';

export const GET_CURRENCY_DETAIL_REQUEST = 'GET_CURRENCY_DETAIL_REQUEST';
export const GET_CURRENCY_DETAIL_SUCCESS = 'GET_CURRENCY_DETAIL_SUCCESS';
export const GET_CURRENCY_DETAIL_ERROR = 'GET_CURRENCY_DETAIL_ERROR';

export const CREATE_CURRENCY_REQUEST = 'CREATE_CURRENCY_REQUEST';
export const CREATE_CURRENCY_SUCCESS = 'CREATE_CURRENCY_SUCCESS';
export const CREATE_CURRENCY_ERROR = 'CREATE_CURRENCY_ERROR';

export const UPDATE_CURRENCY_REQUEST = 'UPDATE_CURRENCY_REQUEST';
export const UPDATE_CURRENCY_SUCCESS = 'UPDATE_CURRENCY_SUCCESS';
export const UPDATE_CURRENCY_ERROR = 'UPDATE_CURRENCY_ERROR';

export const DELETE_CURRENCY_REQUEST = 'DELETE_CURRENCY_REQUEST';
export const DELETE_CURRENCY_SUCCESS = 'DELETE_CURRENCY_SUCCESS';
export const DELETE_CURRENCY_ERROR = 'DELETE_CURRENCY_ERROR';

export const fetchCurrency = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CURRENCY_REQUEST, FETCH_CURRENCY_SUCCESS, FETCH_CURRENCY_ERROR],
      callAPI: () => ApiClient.post('network/Currency/Search', data),
      payload: { data },
    }),
  );
};

export const fetchCurrencyOptions = (entityCode = null) => dispatch => {
  let url = 'network/Currency/GetSelectList';

  if (entityCode) {
    url += `?entityCode=${entityCode}`;
  }

  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CURRENCY_OPTION_REQUEST, FETCH_CURRENCY_OPTION_SUCCESS, FETCH_CURRENCY_OPTION_ERROR],
      callAPI: () => ApiClient.get(url),
      payload: {},
    }),
  );
};

export const fetchPredefinedItems = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_PREDEFINED_ITEMS_REQUEST, FETCH_PREDEFINED_ITEMS_SUCCESS, FETCH_PREDEFINED_ITEMS_ERROR],
      callAPI: () => ApiClient.get('network/Currency/GetPredefinedItems'),
      payload: {},
    }),
  );
};

export const getCurrencyDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CURRENCY_DETAIL_REQUEST, GET_CURRENCY_DETAIL_SUCCESS, GET_CURRENCY_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`network/Currency/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createCurrency = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_CURRENCY_REQUEST, CREATE_CURRENCY_SUCCESS, CREATE_CURRENCY_ERROR],
      callAPI: () => ApiClient.post('network/Currency', data),
      payload: { data, formId },
    }),
  );
};

export const updateCurrency = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_CURRENCY_REQUEST, UPDATE_CURRENCY_SUCCESS, UPDATE_CURRENCY_ERROR],
      callAPI: () => ApiClient.put(`network/Currency/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteCurrency = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_CURRENCY_REQUEST, DELETE_CURRENCY_SUCCESS, DELETE_CURRENCY_ERROR],
      callAPI: () => ApiClient.delete(`network/Currency/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    case FETCH_CURRENCY_OPTION_REQUEST:
    case FETCH_CURRENCY_REQUEST:
    case GET_CURRENCY_DETAIL_REQUEST:
    case CREATE_CURRENCY_REQUEST:
    case UPDATE_CURRENCY_REQUEST:
    case DELETE_CURRENCY_REQUEST:
    case FETCH_PREDEFINED_ITEMS_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_CURRENCY_OPTION_SUCCESS:
      return {
        ...state,
        data: { ...state.data, options: response.data.data },
        response,
        successMessage,
      };

    case FETCH_CURRENCY_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };

    case FETCH_PREDEFINED_ITEMS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          predefinedItems: response.data.data,
        },
        response,
        successMessage,
      };

    case GET_CURRENCY_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_CURRENCY_SUCCESS:
    case UPDATE_CURRENCY_SUCCESS:
    case DELETE_CURRENCY_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    case FETCH_CURRENCY_OPTION_ERROR:
    case FETCH_CURRENCY_ERROR:
    case GET_CURRENCY_DETAIL_ERROR:
    case CREATE_CURRENCY_ERROR:
    case UPDATE_CURRENCY_ERROR:
    case DELETE_CURRENCY_ERROR:
    case FETCH_PREDEFINED_ITEMS_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
