import ApiClient from '../../../helpers/apiClient';
import { startSubmit } from 'redux-form';

// --- Consumer Group ---------------
export const FETCH_CONSUMER_GROUP_OPTIONS_REQUEST = 'FETCH_CONSUMER_GROUP_OPTIONS_REQUEST';
export const FETCH_CONSUMER_GROUP_OPTIONS_SUCCESS = 'FETCH_CONSUMER_GROUP_OPTIONS_SUCCESS';
export const FETCH_CONSUMER_GROUP_OPTIONS_ERROR = 'FETCH_CONSUMER_GROUP_OPTIONS_ERROR';

export const FETCH_CONSUMER_GROUPS_REQUEST = 'FETCH_CONSUMER_GROUPS_REQUEST';
export const FETCH_CONSUMER_GROUPS_SUCCESS = 'FETCH_CONSUMER_GROUPS_SUCCESS';
export const FETCH_CONSUMER_GROUPS_ERROR = 'FETCH_CONSUMER_GROUPS_ERROR';

export const GET_CONSUMER_GROUP_DETAIL_REQUEST = 'GET_CONSUMER_GROUP_DETAIL_REQUEST';
export const GET_CONSUMER_GROUP_DETAIL_SUCCESS = 'GET_CONSUMER_GROUP_DETAIL_SUCCESS';
export const GET_CONSUMER_GROUP_DETAIL_ERROR = 'GET_CONSUMER_GROUP_DETAIL_ERROR';

export const CREATE_CONSUMER_GROUP_REQUEST = 'CREATE_CONSUMER_GROUP_REQUEST';
export const CREATE_CONSUMER_GROUP_SUCCESS = 'CREATE_CONSUMER_GROUP_SUCCESS';
export const CREATE_CONSUMER_GROUP_ERROR = 'CREATE_CONSUMER_GROUP_ERROR';

export const UPDATE_CONSUMER_GROUP_REQUEST = 'UPDATE_CONSUMER_GROUP_REQUEST';
export const UPDATE_CONSUMER_GROUP_SUCCESS = 'UPDATE_CONSUMER_GROUP_SUCCESS';
export const UPDATE_CONSUMER_GROUP_ERROR = 'UPDATE_CONSUMER_GROUP_ERROR';

export const DELETE_CONSUMER_GROUP_REQUEST = 'DELETE_CONSUMER_GROUP_REQUEST';
export const DELETE_CONSUMER_GROUP_SUCCESS = 'DELETE_CONSUMER_GROUP_SUCCESS';
export const DELETE_CONSUMER_GROUP_ERROR = 'DELETE_CONSUMER_GROUP_ERROR';

// --- Consumer --------------------------
export const FETCH_CONSUMERS_REQUEST = 'FETCH_CONSUMERS_REQUEST';
export const FETCH_CONSUMERS_SUCCESS = 'FETCH_CONSUMERS_SUCCESS';
export const FETCH_CONSUMERS_ERROR = 'FETCH_CONSUMERS_ERROR';

export const FETCH_CONSUMER_OPTIONS_REQUEST = 'FETCH_CONSUMER_OPTIONS_REQUEST';
export const FETCH_CONSUMER_OPTIONS_SUCCESS = 'FETCH_CONSUMER_OPTIONS_SUCCESS';
export const FETCH_CONSUMER_OPTIONS_ERROR = 'FETCH_CONSUMER_OPTIONS_ERROR';

export const FETCH_CONSUMER_BY_ENTITY_REQUEST = 'FETCH_CONSUMER_BY_ENTITY_REQUEST';
export const FETCH_CONSUMER_BY_ENTITY_SUCCESS = 'FETCH_CONSUMER_BY_ENTITY_SUCCESS';
export const FETCH_CONSUMER_BY_ENTITY_ERROR = 'FETCH_CONSUMER_BY_ENTITY_ERROR';

export const GET_CONSUMER_DETAIL_REQUEST = 'GET_CONSUMER_DETAIL_REQUEST';
export const GET_CONSUMER_DETAIL_SUCCESS = 'GET_CONSUMER_DETAIL_SUCCESS';
export const GET_CONSUMER_DETAIL_ERROR = 'GET_CONSUMER_DETAIL_ERROR';

export const CREATE_CONSUMER_REQUEST = 'CREATE_CONSUMER_REQUEST';
export const CREATE_CONSUMER_SUCCESS = 'CREATE_CONSUMER_SUCCESS';
export const CREATE_CONSUMER_ERROR = 'CREATE_CONSUMER_ERROR';

export const CREATE_BY_CONSUMER_REQUEST = 'CREATE_BY_CONSUMER_REQUEST';
export const CREATE_BY_CONSUMER_SUCCESS = 'CREATE_BY_CONSUMER_SUCCESS';
export const CREATE_BY_CONSUMER_ERROR = 'CREATE_BY_CONSUMER_ERROR';

export const UPDATE_CONSUMER_REQUEST = 'UPDATE_CONSUMER_REQUEST';
export const UPDATE_CONSUMER_SUCCESS = 'UPDATE_CONSUMER_SUCCESS';
export const UPDATE_CONSUMER_ERROR = 'UPDATE_CONSUMER_ERROR';

export const DELETE_CONSUMER_REQUEST = 'DELETE_CONSUMER_REQUEST';
export const DELETE_CONSUMER_SUCCESS = 'DELETE_CONSUMER_SUCCESS';
export const DELETE_CONSUMER_ERROR = 'DELETE_CONSUMER_ERROR';

export const GENERATE_SUMMARY_COMMISSION_PDF_REQUEST = 'GENERATE_SUMMARY_COMMISSION_PDF_REQUEST';
export const GENERATE_SUMMARY_COMMISSION_PDF_SUCCESS = 'GENERATE_SUMMARY_COMMISSION_PDF_SUCCESS';
export const GENERATE_COMMISSION_SUMMARY_PDF_ERROR = 'GENERATE_COMMISSION_SUMMARY_PDF_ERROR';


//Affiliate Registration
export const fetchConsumerAffiliateRegistrationLists = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CONSUMER_GROUPS_REQUEST, FETCH_CONSUMER_GROUPS_SUCCESS, FETCH_CONSUMER_GROUPS_ERROR],
      callAPI: () => ApiClient.post('customer/ConsumerAffiliateRegistration/Search', data),
      payload: { data },
    }),
  );
};

export const fetchConsumerGoalLists = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CONSUMER_GROUPS_REQUEST, FETCH_CONSUMER_GROUPS_SUCCESS, FETCH_CONSUMER_GROUPS_ERROR],
      callAPI: () => ApiClient.post('customer/ConsumerUpgradeGoal/Search', data),
      payload: { data },
    }),
  );
};

export const getConsumerGoalDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CONSUMER_GROUP_DETAIL_REQUEST, GET_CONSUMER_GROUP_DETAIL_SUCCESS, GET_CONSUMER_GROUP_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`customer/ConsumerUpgradeGoal/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const getConsumerAffiliateRegistrationDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CONSUMER_GROUP_DETAIL_REQUEST, GET_CONSUMER_GROUP_DETAIL_SUCCESS, GET_CONSUMER_GROUP_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`customer/ConsumerAffiliateRegistration/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const approveConsumerAffiliate = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_CONSUMER_GROUP_REQUEST, DELETE_CONSUMER_GROUP_SUCCESS, DELETE_CONSUMER_GROUP_ERROR],
      callAPI: () => ApiClient.put(`customer/ConsumerAffiliateRegistration/ApproveStatus/${id}`),
      payload: { data: { id } },
    }),
  );
};


// --- Consumer Group ---------
export const fetchConsumerGroups = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CONSUMER_GROUPS_REQUEST, FETCH_CONSUMER_GROUPS_SUCCESS, FETCH_CONSUMER_GROUPS_ERROR],
      callAPI: () => ApiClient.post('customer/ConsumerGroups/Search', data),
      payload: { data },
    }),
  );
};

export const fetchConsumerGroupOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        FETCH_CONSUMER_GROUP_OPTIONS_REQUEST,
        FETCH_CONSUMER_GROUP_OPTIONS_SUCCESS,
        FETCH_CONSUMER_GROUP_OPTIONS_ERROR,
      ],
      callAPI: () => ApiClient.get('customer/ConsumerGroups/GetSelectList'),
      payload: {},
    }),
  );
};

export const getConsumerGroupDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CONSUMER_GROUP_DETAIL_REQUEST, GET_CONSUMER_GROUP_DETAIL_SUCCESS, GET_CONSUMER_GROUP_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`customer/ConsumerGroups/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createConsumerGroup = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_CONSUMER_GROUP_REQUEST, CREATE_CONSUMER_GROUP_SUCCESS, CREATE_CONSUMER_GROUP_ERROR],
      callAPI: () => ApiClient.post('customer/ConsumerGroups', data),
      payload: { data, formId },
    }),
  );
};

export const updateConsumerGroup = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_CONSUMER_GROUP_REQUEST, UPDATE_CONSUMER_GROUP_SUCCESS, UPDATE_CONSUMER_GROUP_ERROR],
      callAPI: () => ApiClient.put(`customer/ConsumerGroups/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteConsumerGroup = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_CONSUMER_GROUP_REQUEST, DELETE_CONSUMER_GROUP_SUCCESS, DELETE_CONSUMER_GROUP_ERROR],
      callAPI: () => ApiClient.delete(`customer/ConsumerGroups/${id}`),
      payload: { data: { id } },
    }),
  );
};

// --- Consumer ---------------
export const fetchConsumers = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CONSUMERS_REQUEST, FETCH_CONSUMERS_SUCCESS, FETCH_CONSUMERS_ERROR],
      callAPI: () => ApiClient.post('customer/Consumer/Search', data),
      payload: { data },
    }),
  );
};

export const fetchConsumerAffiliates = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CONSUMERS_REQUEST, FETCH_CONSUMERS_SUCCESS, FETCH_CONSUMERS_ERROR],
      callAPI: () => ApiClient.post('customer/ConsumerAffiliate/Search', data),
      payload: { data },
    }),
  );
};

export const generateAffiliateSummary = (startDate, endDate) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GENERATE_SUMMARY_COMMISSION_PDF_REQUEST,
        GENERATE_SUMMARY_COMMISSION_PDF_SUCCESS,
        GENERATE_COMMISSION_SUMMARY_PDF_ERROR
      ],
      callAPI: () => ApiClient.post(`customer/ConsumerAffiliate/GenerateSummaryPDF/${startDate}/${endDate}`),
      payload: { data: { startDate, endDate } },
    }),
  );
};

export const fetchConsumerCommissions = data => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CONSUMERS_REQUEST, FETCH_CONSUMERS_SUCCESS, FETCH_CONSUMERS_ERROR],
      callAPI: () => ApiClient.post('customer/ConsumerCommission/Search', data),
      payload: { data },
    }),
  );
};

export const generateCommissionSummary = (startDate, endDate) => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [
        GENERATE_SUMMARY_COMMISSION_PDF_REQUEST,
        GENERATE_SUMMARY_COMMISSION_PDF_SUCCESS,
        GENERATE_COMMISSION_SUMMARY_PDF_ERROR
      ],
      callAPI: () => ApiClient.post(`customer/ConsumerCommission/GenerateCommissionSummaryPDF/${startDate}/${endDate}`),
      payload: { data: { startDate, endDate } },
    }),
  );
};

export const fetchConsumerOptions = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CONSUMER_OPTIONS_REQUEST, FETCH_CONSUMER_OPTIONS_SUCCESS, FETCH_CONSUMER_OPTIONS_ERROR],
      callAPI: () => ApiClient.get('customer/Consumer/GetSelectList'),
      payload: {},
    }),
  );
};

export const fetchConsumerByEntity = () => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [FETCH_CONSUMER_BY_ENTITY_REQUEST, FETCH_CONSUMER_BY_ENTITY_SUCCESS, FETCH_CONSUMER_BY_ENTITY_ERROR],
      callAPI: () => ApiClient.get('customer/Consumer/GetByEntity'),
      payload: {},
    }),
  );
};

export const getConsumerDetail = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [GET_CONSUMER_DETAIL_REQUEST, GET_CONSUMER_DETAIL_SUCCESS, GET_CONSUMER_DETAIL_ERROR],
      callAPI: () => ApiClient.get(`customer/Consumer/GetDetail/${id}`),
      payload: { data: { id } },
    }),
  );
};

export const createConsumer = (data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_CONSUMER_REQUEST, CREATE_CONSUMER_SUCCESS, CREATE_CONSUMER_ERROR],
      callAPI: () => ApiClient.post('customer/Consumer', data),
      payload: { data, formId },
    }),
  );
};

export const createByInvitation = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [CREATE_BY_CONSUMER_REQUEST, CREATE_BY_CONSUMER_SUCCESS, CREATE_BY_CONSUMER_ERROR],
      callAPI: () => ApiClient.post('customer/Consumer/CreateByInvitation/' + id, data),
      payload: { data, formId },
    }),
  );
};

export const updateConsumer = (id, data, formId) => dispatch => {
  return Promise.resolve(dispatch(startSubmit(formId))).then(() =>
    dispatch({
      types: [UPDATE_CONSUMER_REQUEST, UPDATE_CONSUMER_SUCCESS, UPDATE_CONSUMER_ERROR],
      callAPI: () => ApiClient.put(`customer/Consumer/${id}`, data),
      payload: { data: { ...data, id }, formId },
    }),
  );
};

export const deleteConsumer = id => dispatch => {
  return Promise.resolve().then(() =>
    dispatch({
      types: [DELETE_CONSUMER_REQUEST, DELETE_CONSUMER_SUCCESS, DELETE_CONSUMER_ERROR],
      callAPI: () => ApiClient.delete(`customer/Consumer/${id}`),
      payload: { data: { id } },
    }),
  );
};

const initialState = {
  payload: {},
  data: {},
  response: {},
  error: {},
  errorMessage: '',
  successMessage: '',
};

export default function reducer(state = initialState, action) {
  const { payload, response, error, successMessage, errorMessage } = action;
  switch (action.type) {
    // Consumer Group
    case FETCH_CONSUMER_GROUP_OPTIONS_REQUEST:
    case FETCH_CONSUMER_GROUPS_REQUEST:
    case GET_CONSUMER_GROUP_DETAIL_REQUEST:
    case CREATE_CONSUMER_GROUP_REQUEST:
    case UPDATE_CONSUMER_GROUP_REQUEST:
    case DELETE_CONSUMER_GROUP_REQUEST:
    // Consumer
    case FETCH_CONSUMERS_REQUEST:
    case FETCH_CONSUMER_OPTIONS_REQUEST:
    case FETCH_CONSUMER_BY_ENTITY_REQUEST:
    case GET_CONSUMER_DETAIL_REQUEST:
    case CREATE_CONSUMER_REQUEST:
    case CREATE_BY_CONSUMER_REQUEST:
    case UPDATE_CONSUMER_REQUEST:
    case DELETE_CONSUMER_REQUEST:
      return {
        ...state,
        payload,
        response: {},
        error: {},
        successMessage: '',
        errorMessage: '',
      };

    case FETCH_CONSUMER_GROUP_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, consumerGroupOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_CONSUMER_OPTIONS_SUCCESS:
      return {
        ...state,
        data: { ...state.data, consumerOptions: response.data.data },
        response,
        successMessage,
      };
    case FETCH_CONSUMER_BY_ENTITY_SUCCESS:
      return {
        ...state,
        data: { ...state.data, userConsumers: response.data.data },
        response,
        successMessage,
      };

    case FETCH_CONSUMER_GROUPS_SUCCESS:
    case FETCH_CONSUMERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          items: response.data.data.items,
          pages: response.data.data.pageCount,
        },
        response,
        successMessage,
      };
    case GET_CONSUMER_GROUP_DETAIL_SUCCESS:
    case GET_CONSUMER_DETAIL_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          item: response.data.data,
        },
        response,
        successMessage,
      };

    case CREATE_CONSUMER_GROUP_SUCCESS:
    case UPDATE_CONSUMER_GROUP_SUCCESS:
    case DELETE_CONSUMER_GROUP_SUCCESS:
    case CREATE_CONSUMER_SUCCESS:
    case CREATE_BY_CONSUMER_SUCCESS:
    case UPDATE_CONSUMER_SUCCESS:
    case DELETE_CONSUMER_SUCCESS:
      return {
        ...state,
        response,
        successMessage,
      };

    // Consumer Group
    case FETCH_CONSUMER_GROUP_OPTIONS_ERROR:
    case FETCH_CONSUMER_GROUPS_ERROR:
    case GET_CONSUMER_GROUP_DETAIL_ERROR:
    case CREATE_CONSUMER_GROUP_ERROR:
    case UPDATE_CONSUMER_GROUP_ERROR:
    case DELETE_CONSUMER_GROUP_ERROR:

    // Consumer
    case FETCH_CONSUMERS_ERROR:
    case FETCH_CONSUMER_OPTIONS_ERROR:
    case FETCH_CONSUMER_BY_ENTITY_ERROR:
    case GET_CONSUMER_DETAIL_ERROR:
    case CREATE_CONSUMER_ERROR:
    case CREATE_BY_CONSUMER_ERROR:
    case UPDATE_CONSUMER_ERROR:
    case DELETE_CONSUMER_ERROR:
      return {
        ...state,
        payload,
        error,
        errorMessage,
      };
    default:
      return state;
  }
}
